@import "palettes";
@import "theme";
@import "mixins";

ul,
ol {
  margin: 20px 0;
  @include trim-v-margins;
  padding-left: 40px;

  li {
    margin: 10px 0;
    @include trim-v-margins;
    padding-left: 10px;
    text-align: left;
    line-height: $spacing + 0.3;
  }
}

ul {
  list-style-type: square;
}
